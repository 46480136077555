import {
  addNewPatient,
  checkFingerprint,
  login,
  removePatient
} from '@/communication/bundles_min'

const stateObj = {
  patientId: null,
  handSelected: 1, // right hand
  fingerSelected: 1 // index finger
}

const getters = {
  getPatientId (state) {
    return state.patientId
  },
  getHandSelected (state) {
    return state.handSelected
  },
  getFingerSelected (state) {
    return state.fingerSelected
  }
}
const mutations = {
  setPatientId (state, value) {
    state.patientId = value
  },
  resetPatientId (state) {
    state.patientId = null
  },
  setHandSelected (state, value) {
    state.handSelected = value
  },
  resetHandSelected (state) {
    state.handSelected = 1
  },
  setFingerSelected (state, value) {
    state.fingerSelected = value
  },
  resetFingerSelected (state) {
    state.fingerSelected = 1
  }
}

const actions = {
  checkIfNotExistingFingerprint ({ commit }, payload) {
    return checkFingerprint(payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        // Pass entire error object
        throw error
      })
  },
  createPatient ({ commit }, payload) {
    return addNewPatient(payload)
      .then((response) => {
        commit('setPatientId', response.hhid)
        return response
      })
      .catch(error => {
        // Pass entire error object
        throw error
      })
  },
  deletePatient ({ commit }, payload) {
    return removePatient(payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        throw Error(`${error.name}` + ': Error while removing a patient')
      })
  },
  loginPatient ({ commit }, payload) {
    return login(payload)
      .then((response) => {
        commit('setPatientId', response.hhid)
        return response
      })
      .catch(error => {
        // Pass entire error object
        throw error
      })
  }
}

export default {
  actions,
  getters,
  mutations,
  state: stateObj
}

import {
  getPatientIdentifications,
  getRecentIdentifications
} from '@/communication/bundles_min'

const stateObj = {
  patientIdentifications: [],
  recentIdentifications: [],
  selectedHHID: ''
}

const getters = {
  getPatientIdentifications (state) {
    // Needed in reverse order
    return state.patientIdentifications.slice().reverse()
  },
  getRecentIdentifications (state) {
    return state.recentIdentifications
  }
}

const mutations = {
  setPatientIdentifications (state, value) {
    state.patientIdentifications = value
  },
  setRecentIdentifications (state, value) {
    state.recentIdentifications = value
  },
  setSelectedHHID (state, value) {
    state.selectedHHID = value
  }
}

const actions = {
  fetchRecentIdentifications ({ commit }, payload) {
    getRecentIdentifications(payload)
      .then(response => {
        commit('setRecentIdentifications', response)
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  },
  fetchActivityById ({ commit }, payload) {
    getPatientIdentifications(payload)
      .then(response => {
        commit('setPatientIdentifications', response)
        commit('setSelectedHHID', response.HHID)
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  }
}

export default {
  actions,
  getters,
  mutations,
  state: stateObj
}

import { ACCESS_TOKEN, HTTP_ERROR_UNAUTHORIZED, USER_DATA } from '@/constants/constants'
import { datadogRum } from '@datadog/browser-rum'

class Auth {
  checkAuth () {
    return localStorage.getItem(ACCESS_TOKEN) && localStorage.getItem(USER_DATA)
  }

  clearToken () {
    localStorage.removeItem(ACCESS_TOKEN)
  }

  clearUserData () {
    localStorage.removeItem(USER_DATA)
  }

  clearAll () {
    this.clearToken()
    this.clearUserData()
    datadogRum.clearUser()
  }

  getAuthHeader () {
    const token = localStorage.getItem(ACCESS_TOKEN)
    return token ? `${token}` : null
  }

  getUserData () {
    return JSON.parse(localStorage.getItem(USER_DATA))
  }

  isUnauthorized (error) {
    if (error.request.status === HTTP_ERROR_UNAUTHORIZED) {
      this.logout()
    }
  }

  logout () {
    this.clearAll()
    window.location = '/'
  }
}

export default new Auth()

const stateObj = {
  sensors: []
}

const mutations = {
  setSensors (state, value) {
    state.sensors = value
  }
}

export default {
  mutations,
  state: stateObj
}

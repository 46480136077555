import { _get, _save } from '@/communication/api'

const URL_BASE_RECENT_IDENTIFICATIONS = 'app/api/v1/recent-identifications'
const URL_BASE_ACTIVITY_BY_ID = 'app/api/v1/activity-by-id'

/**
 * Get a list of identifications for a patient HHID
 * @param {Object} params - The object with the url params
 * @param {String} sensorSN - The sensorSN as security param
 * @param {String} dateInit - The date from when filter the identification results
 * @param {String} dateEnd - The date until when filter the identification results
 * @param {String} hhid - The hhid of the patient who will be consulted
 * @return {Promise} - A promise with an array of identifications data
 */
export function getPatientIdentifications ( params ) {
  const url = `${URL_BASE_ACTIVITY_BY_ID}?sensorSN=${params.sensorSN}&dateInit=${params.dateInit}&dateEnd=${params.dateEnd}&hhid=${params.hhid}`
  return _get(url)
}

/**
 * Get a list of recent identifications betweent two dates
 * @param {Object} params - The object with the url params
 * @param {String} sensorSN - The sensorSN as security param
 * @param {String} dateInit - The date from when filter the identification results
 * @param {String} dateEnd - The date until when filter the identification results
 * @return {Promise} - A promise with an array of identifications data
 */
 export function getRecentIdentifications ( params ) {
  const url = `${URL_BASE_RECENT_IDENTIFICATIONS}?sensorSN=${params.sensorSN}&dateInit=${params.dateInit}&dateEnd=${params.dateEnd}`
  return _get(url)
}
